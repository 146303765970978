import _ from 'lodash'

class SetsHelper {

  /**
   * จัดเรียงตัวเลขใหม่
   */
  setGroupNumber(number) {
    return _.sortBy(number.slice(''), (i)=>{
      return parseInt(i)
    }).join('')
  }

  gThreeNumberTode(number) {
    if(!number)
      return []

    const n = number.slice('')
    const numbers = [
      `${n[0]}${n[1]}${n[2]}`,
      `${n[0]}${n[2]}${n[1]}`,
      `${n[1]}${n[0]}${n[2]}`,
      `${n[1]}${n[2]}${n[0]}`,
      `${n[2]}${n[1]}${n[0]}`,
      `${n[2]}${n[0]}${n[1]}`,
    ]

    return _.uniqBy(numbers)
  }

  gFourNumberTode(number) {
    if(!number)
      return []

    const n = number.slice('')
    const numbers = [
      `${n[0]}${n[1]}${n[2]}${n[3]}`,
      `${n[0]}${n[1]}${n[3]}${n[2]}`,
      `${n[0]}${n[2]}${n[1]}${n[3]}`,
      `${n[0]}${n[2]}${n[3]}${n[1]}`,
      `${n[0]}${n[3]}${n[1]}${n[2]}`,
      `${n[0]}${n[3]}${n[2]}${n[1]}`,
      `${n[1]}${n[0]}${n[2]}${n[3]}`,
      `${n[1]}${n[0]}${n[3]}${n[2]}`,
      `${n[1]}${n[2]}${n[0]}${n[3]}`,
      `${n[1]}${n[2]}${n[3]}${n[0]}`,
      `${n[1]}${n[3]}${n[0]}${n[2]}`,
      `${n[1]}${n[3]}${n[2]}${n[0]}`,
      `${n[2]}${n[0]}${n[1]}${n[3]}`,
      `${n[2]}${n[0]}${n[3]}${n[1]}`,
      `${n[2]}${n[1]}${n[0]}${n[3]}`,
      `${n[2]}${n[1]}${n[3]}${n[0]}`,
      `${n[2]}${n[3]}${n[0]}${n[1]}`,
      `${n[2]}${n[3]}${n[1]}${n[0]}`,
      `${n[3]}${n[0]}${n[1]}${n[2]}`,
      `${n[3]}${n[0]}${n[2]}${n[1]}`,
      `${n[3]}${n[1]}${n[0]}${n[2]}`,
      `${n[3]}${n[1]}${n[2]}${n[0]}`,
      `${n[3]}${n[2]}${n[1]}${n[0]}`,
      `${n[3]}${n[2]}${n[0]}${n[1]}`,
    ]

    return _.uniqBy(numbers)
  }
}

export default new SetsHelper()
